import React from 'react';
import logo from '../assets/img/rankim-preto.png';
import logo2 from '../assets/img/rankim-branco-pequeno.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Logo(props) {
    let url = (props.url) ? props.url : '/';
    return (
        <a href={url} className={`logo ${props?.className || ''}`}>
            <LazyLoadImage alt="rankim" src={!props.lightGreen ? logo : logo2} />
        </a>
    );
};

export default Logo;