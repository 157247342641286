
import './privacyPolicy.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import React from "react";
import { Helmet } from "react-helmet";

function privacyPolicy() {
  return (
    <div className="privacy-policy">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,automação,leads,corretor,whatsapp,landing page,captação de leads,crm corretor,crm imobiliária,marketing,rankim,geração de leads,corretor de imóveis" />
        <meta name="description" content="Plataforma de automação de marketing, captação de leads e CRM para corretores de imóveis e imobiliárias, conteúdo exclusivo" />
        <meta name="author" content="rankim" />
        <title>rankim: Plataforma de compra e venda de imóveis</title>
        <meta property="fb:app_id" content="" />
        <meta property="og:type" content="article" />
        <meta property="profile:username" content="voceafrente" />
        <meta property="og:title" content="Plataforma imobiliária de marketing e CRM para.." />
        <meta property="og:description" content="Plataforma de automação de marketing, captação.." />
        <meta property="og:url" content="https://rankim.com.br/" />
        <meta property="og:site_name" content="rankim" />
        <meta property="og:see_also" content="https://rankim.com.br/" />
        <meta property="og:image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
        <meta property="og:image:url" itemprop="image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <Header />
      <main className="container large pv-20 mb-60">
        <h1 className="mt-40 mb-20">Política de Privacidade</h1>
        <h3>1. Informações gerais</h3>
        <p>A presente Política de Privacidade contém informações a respeito do modo como tratamos, total
        ou parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam
        nosso site e aplicativo. Seu objetivo é esclarecer os interessados acerca dos tipos de dados que
        são coletados, dos motivos da coleta e da forma como o usuário poderá atualizar, gerenciar ou
        excluir estas informações. Esta Política de Privacidade foi elaborada em conformidade com a Lei
        Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n. 13.709,
        de 14 de agosto de 2018 (Lei Geral de Proteção aos Dados - LGPD) e com o Regulamento UE n.
        2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais -
        RGDP). Esta Política de Privacidade poderá ser atualizada em decorrência de eventual
        atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta
                seção.</p>
        <h3>2. Direitos do usuário</h3>
        <p>O site e aplicativo se comprometem a cumprir as normas previstas no RGPD, em respeito aos
                seguintes princípios: </p>
        <ul className="dashed">
          <li>
            Os dados pessoais do usuário serão processados de forma lícita, leal e transparente (licitude,
            lealdade e transparência);
                </li>
          <li>
            Os dados pessoais do usuário serão coletados apenas para finalidades determinadas, explícitas
            e legítimas, não podendo ser tratados posteriormente de uma forma incompatível com essas
            finalidades (limitação das finalidades);
                </li>
          <li>
            Os dados pessoais do usuário serão coletados de forma adequada, pertinente e limitada às
            necessidades do objetivo para os quais eles são processados (minimização dos dados);
                </li>
          <li>
            Os dados pessoais do usuário serão exatos e atualizados sempre que necessário, de maneira
            que os dados inexatos sejam apagados ou retificados quando possível (exatidão);
                </li>
          <li>
            Os dados pessoais do usuário serão conservados de uma forma que permita a identificação dos
            titulares dos dados apenas durante o período necessário para as finalidades para as quais são
            tratados (limitação da conservação);
                </li>
          <li>
            Os dados pessoais do usuário serão tratados de forma segura, protegidos do tratamento não
            autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental, adotando as
            medidas técnicas ou organizativas adequadas (integridade e confidencialidade). O usuário do
            site e aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais
            e pelo RGPD:
                  <ul className="dashed mt-20 ml-30">
              <li>
                Direito de confirmação e acesso: é o direito do usuário de obter do site e aplicativo a
                confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento
                e, se for esse o caso, o direito de acessar os seus dados pessoais;
                    </li>
              <li>
                Direito de retificação: é o direito do usuário de obter do site e aplicativo, sem demora
                injustificada, a retificação dos dados pessoais inexatos que lhe digam respeito;
                    </li>
              <li>
                Direito à eliminação dos dados (direito ao esquecimento): é o direito do usuário de ter seus
                dados apagados do site e aplicativo;
                    </li>
              <li>
                Direito à limitação do tratamento dos dados: é o direito do usuário de limitar o tratamento de
                seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o
                tratamento for ilícito, quando o site e aplicativo não precisarem mais dos dados para as
                finalidades propostas e quando tiver se oposto ao tratamento dos dados e em caso de
                tratamento de dados desnecessários;
                    </li>
              <li>
                Direito de oposição: é o direito do usuário de, a qualquer momento, se opor por motivos
                relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam
                respeito, podendo se opor ainda ao uso de seus dados pessoais para definição de perfil de
                marketing (profiling);
                    </li>
              <li>
                Direito de portabilidade dos dados: é o direito do usuário de receber os dados pessoais que lhe
                digam respeito e que tenha fornecido ao site e aplicativo, num formato estruturado, de uso
                corrente e de leitura automática, e o direito de transmitir esses dados a outro site e aplicativo;
                    </li>
              <li>
                Direito de não ser submetido a decisões automatizadas: é o direito do usuário de não ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado,
                incluindo a definição de perfis (profiling), que produza efeitos na sua esfera jurídica ou que o afete significativamente de forma similar. O usuário poderá exercer os seus direitos por meio de
                comunicação escrita enviada ao site e aplicativo com o assunto "RGDP-", especificando:
                      <ul className="dashed mt-20 ml-30">
                  <li>Nome completo ou razão social, número do CPF (Cadastro de Pessoas Físicas, da
                  Receita Federal do Brasil) ou CNPJ (Cadastro Nacional de Pessoa Jurídica, da Receita
                          Federal do Brasil) e endereço de e-mail do usuário e, se for o caso, do seu representante; </li>
                  <li>Direito que deseja exercer junto ao site e aplicativo; </li>
                  <li>Data do pedido e assinatura do usuário; </li>
                  <li>Todo documento que possa demonstrar ou justificar o exercício de seu direito. </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <p>O pedido deverá ser enviado ao e-mail: contato@rankim.com.br, ou por correio, ao seguinte
        endereço: rankim Soluções de Software Ltda., Rua 1301, 471 Sala 1104 - Ed. Top Class Corporate
        - Centro - Balneário Camboriú/SC, CEP: 88330-975. O usuário será informado em caso de
                retificação ou eliminação dos seus dados.</p>
        <h3>3. Dever de não fornecer dados de terceiros</h3>
        <p>Durante a utilização do site, a fim de resguardar e de proteger os direitos de terceiros, o usuário
                do site e aplicativo deverá fornecer somente seus dados pessoais, e não os de terceiros</p>
        <h3>4. Tipos de dados coletados</h3>
        <h5>4.1. Dados de identificação do usuário para realização de cadastro</h5>
        <p>A utilização, pelo usuário, de determinadas funcionalidades do site e aplicativo dependerá de
        cadastro, sendo que, nestes casos, os seguintes dados do usuário serão coletados e
                armazenados: - Nome - Endereço de e-mail - Endereço postal - Número de telefone </p>
        <p className="italic">4.1.1. Dados informados no formulário de contato</p>
        <p>Os dados eventualmente informados pelo usuário que utilizar o formulário de contato
        disponibilizado no site e aplicativo, incluindo o teor da mensagem enviada, serão coletados e
                armazenados.</p>
        <p className="italic">4.1.2. Dados relacionados à execução de contratos firmados com o usuário</p>
        <p>Para a execução de contrato de compra e venda ou de prestação de serviços eventualmente
        firmado entre o site e aplicativo e o usuário, poderão ser coletados e armazenados outros dados
        relacionados ou necessários a sua execução, incluindo o teor de eventuais comunicações tidas
                com o usuário.</p>
        <p className="italic">4.1.3. Newsletter</p>
        <p>O endereço de e-mail cadastrado pelo usuário que optar por se inscrever em nossa Newsletter
                será coletado e armazenado até que o usuário solicite seu descadastro.</p>
        <p className="italic">4.1.4. Dados sensíveis</p>
        <p>O site e aplicativo poderão coletar os seguintes dados sensíveis dos usuários: - Telefone de
                contato; - Endereço residencial ou endereço comercial;</p>
        <h5>4.2. Fundamento jurídico para o tratamento dos dados pessoais</h5>
        <p>Ao utilizar os serviços do site e aplicativo, o usuário está consentindo com a presente Política de
        Privacidade. O usuário tem o direito de retirar seu consentimento a qualquer momento, não
        comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. A retirada
        do consentimento poderá ser feita pelo e-mail: contato@rankim.com.br, ou por correio enviado
        ao seguinte endereço: rankim Soluções de Software Ltda., Rua 1301, 471 Sala 1104 - Ed. Top
        Class Corporate - Centro - Balneário Camboriú/SC, CEP: 88330-975. O consentimento dos
        relativamente ou absolutamente incapazes, especialmente de crianças menores de 16
        (dezesseis) anos, apenas poderá ser feito, respectivamente, se devidamente assistidos ou
        representados. Poderão ainda ser coletados dados pessoais necessários para a execução e
        cumprimento dos serviços contratados pelo usuário no site e aplicativo. O tratamento de dados
        pessoais sem o consentimento do usuário apenas será realizado em razão de interesse legítimo
                ou para as hipóteses previstas em lei, ou seja, dentre outras, as seguintes: </p>
        <ul className="dashed">
          <li>Para o cumprimento de obrigação legal ou regulatória pelo controlador; </li>
          <li>Para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a
                  anonimização dos dados pessoais; </li>
          <li>Quando necessário para a execução de contrato ou de procedimentos preliminares
                  relacionados a contrato do qual seja parte o usuário, a pedido do titular dos dados; </li>
          <li>Para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último
                  nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem); </li>
          <li>Para a proteção da vida ou da incolumidade física do titular dos dados ou de terceiros; </li>
          <li>Para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por
                  entidades sanitárias; </li>
          <li>Quando necessário para atender aos interesses legítimos do controlador ou de terceiros,
          exceto no caso de prevalecerem direitos e liberdades fundamentais do titular dos dados que
                  exijam a proteção dos dados pessoais; </li>
          <li>Para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente;</li>
        </ul>
        <h5>4.3. Finalidades do tratamento dos dados pessoais</h5>
        <p>Os dados pessoais do usuário coletados pelo site e aplicativo têm por finalidade facilitar, agilizar
        e cumprir os compromissos estabelecidos com o usuário e a fazer cumprir as solicitações
        realizadas por meio do preenchimento de formulários. Os dados pessoais poderão ser utilizados
        também com uma finalidade comercial, para personalizar o conteúdo oferecido ao usuário, bem
        como para dar subsídio ao site e aplicativo para a melhora da qualidade e funcionamento de
        seus serviços. Os dados de cadastro serão utilizados para permitir o acesso do usuário à
        determinado conteúdo do site e aplicativo, exclusivos para usuários cadastrados. A coleta de
        dados relacionados ou necessários à execução de um contrato de compra e venda ou de
        prestação de serviços eventualmente firmado com o usuário terá a finalidade de conferir às
        partes segurança jurídica, além de facilitar e viabilizar a conclusão do negócio. O tratamento de
        dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá
        mediante comunicação prévia ao usuário, sendo que, em qualquer caso, os direitos e obrigações
                  aqui previstos permanecerão aplicáveis.</p>
        <h5>4.4. Prazo de conservação dos dados pessoais</h5>
        <p>Os dados pessoais do usuário serão conservados por um período não superior ao exigido para
        cumprir os objetivos em razão dos quais eles são processados. O período de conservação dos
        dados é definido de acordo com os seguintes critérios: Os dados ficarão armazenados enquanto
        o usuário permanecer com a conta ativa no site e aplicativo. O usuário poderá solicitar a exclusão
        da conta e seus dados a qualquer momento. Os dados pessoais dos usuários apenas poderão
                ser conservados após o término de seu tratamento nas seguintes hipóteses: </p>
        <ul className="dashed">
          <li>Para o cumprimento de obrigação legal ou regulatória pelo controlador; </li>
          <li>Para estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados
                  pessoais; </li>
          <li>Para a transferência a terceiro, desde que respeitados os requisitos de tratamento de dados
                  dispostos na legislação; </li>
          <li>Para uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que sejam
                  anônimos os dados.</li>
        </ul>
        <h5>4.5. Destinatários e transferência dos dados pessoais</h5>
        <p>Os dados pessoais do usuário não serão compartilhados com terceiros. Serão, portanto, tratados
                apenas por este site e aplicativo.</p>
        <h3>5. Do tratamento dos dados pessoais</h3>
        <h5>5.1. Do responsável pelo tratamento dos dados (data controller) </h5>
        <p>O controlador, responsável pelo tratamento dos dados pessoais do usuário, é a pessoa física ou
        jurídica, a autoridade pública, a agência ou outro organismo que, individualmente ou em
        conjunto com outras, determina as finalidades e os meios de tratamento de dados pessoais.
        Neste site e aplicativo, a responsável pelo tratamento dos dados pessoais coletados é PHP
        Enterprise Software LTDA., que poderá ser contactada pelo seguinte e-mail:
        apps@phpenterprise.com ou no endereço: Rua Felipe Schmidt, 835, SL 1 – Centro
        Florianópolis/SC - CEP: 88010001. O site e aplicativo possuem também os seguintes
        responsáveis pelo tratamento dos dados pessoais coletados: Nome Fantasia: rankim, Razão
        Social: rankim Soluções de Software Ltda., CNPJ: 23.186.832/0001-40, Endereço: Rua 1301, 471
        Sala 1104 - Ed. Top Class Corporate - Centro - Balneário Camboriú/SC, CEP: 88330-975. O
        responsável pelo tratamento dos dados se encarregará diretamente do tratamento dos dados
                pessoais do usuário. </p>
        <h5>5.2. Do encarregado de proteção de dados (data protection officer)</h5>
        <p>O encarregado de proteção de dados (data protection officer) é o profissional encarregado de
        informar, aconselhar e controlar o responsável pelo tratamento dos dados, bem como os
        trabalhadores que tratem os dados, a respeito das obrigações do site e aplicativo nos termos do
        RGDP, da Lei de Proteção de Dados Pessoais e de outras disposições de proteção de dados
        presentes na legislação nacional e internacional, em cooperação com a autoridade de controle
        competente. Neste site e aplicativo, a responsável pelo tratamento dos dados pessoais
        coletados é PHP Enterprise Software LTDA., que poderá ser contactada pelo seguinte e-mail:
                apps@phpenterprise.com.</p>
        <h3>6. Segurança no tratamento dos dados pessoais do usuário</h3>
        <p>O site e aplicativo se comprometem a aplicar as medidas técnicas e organizativas aptas a
        proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda,
        alteração, comunicação ou difusão de tais dados. Para a garantia da segurança, serão adotadas
        soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza,
        o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do
        usuário. O site e aplicativo utilizam certificado SSL (Secure Socket Layer) que garante que os
        dados pessoais se transmitam de forma segura e confidencial, de maneira que a transmissão
        dos dados entre o servidor e o usuário, e em retroalimentação, ocorra de maneira totalmente
        cifrada ou encriptada. No entanto, o site e aplicativo se eximem de responsabilidade por culpa
        exclusiva de terceiros, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do
        usuário, como no caso em que ele mesmo transfere seus dados a terceiro. O site e aplicativo se
        comprometem, ainda, a comunicar o usuário em prazo adequado caso ocorra algum tipo de
        violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus
        direitos e liberdades pessoais. A violação de dados pessoais é uma violação de segurança que
        provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o
        acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro
        tipo de tratamento. Por fim, o site e aplicativo se comprometem a tratar os dados pessoais do
                usuário com confidencialidade, dentro dos limites legais.</p>
        <h3>7. Reclamação e sugestões de melhorias</h3>
        <p>O usuário poderá enviar por correio eletrônico; para contato@rankim.com.br reclamações
        sobre problemas ou falhas ocasionais (bugs) no site e aplicativo, como também sugerir
                melhorias.</p>
        <h3>8. Das alterações</h3>
        <p>A presente versão desta Política de Privacidade foi atualizada pela última vez em: 03/02/2021.
        O editor se reserva o direito de modificar, a qualquer momento o site e aplicativo e as presentes
        normas, especialmente para adaptá-las às evoluções do site e aplicativo rankim, seja pela
        disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já
        existentes. O usuário será explicitamente notificado em caso de alteração desta política. Ao
        utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as
        novas normas. Caso discorde de alguma das modificações, deverá pedir, imediatamente, o
        cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o
                desejar.</p>
        <h3>9. Do Direito aplicável e do foro</h3>
        <p>Para a solução das controvérsias decorrentes do presente instrumento, será aplicado
        integralmente o Direito brasileiro. Os eventuais litígios deverão ser apresentados no foro da
                comarca em que se encontra a sede do editor do site e aplicativo.</p>
      </main>
      <Footer />
    </div>
  );
}

export default privacyPolicy;
